<template>
  <div class="loginOuter">
    <div class="loginForm custom-form">
      <div class="logo-heaer">
        <img src="../assets/img/logo.png" />
      </div>
      <form class="loginField" @submit.prevent="submitForm">
        <div
          class="alert alert-danger"
          :class="{ 'alert-active': errorMessage != null }"
        >
          <strong>Error!</strong> {{ errorMessage }}
        </div>
        <div
          class="alert alert-success"
          :class="{ 'alert-active': successMessage != null }"
        >
          <strong>Success!</strong> {{ successMessage }}
        </div>
        <div class="inputCol">
          <label
            >Group Type
            <span class="text-danger error-text"
              >*<span v-if="v$.usergroupuid.$error"
                >select an option</span
              ></span
            ></label
          >
          <select
            v-model="formFields.usergroupuid"
            @change="selectGroup(formFields.usergroupuid)"
            class="custom-field"
            :class="{ errorCustomField: v$.usergroupuid.$error }"
          >
            <option
              v-for="(item, index) in userGroup"
              :key="index"
              v-bind:value="item.type_uid"
            >
              {{ item.type_user }}
            </option>
          </select>
        </div>
        <div class="inputCol">
          <label
            >User Type
            <span class="text-danger error-text">
              * <span v-if="v$.usertypeuid.$error"> select an option </span>
            </span></label
          >
          <select
            v-model="formFields.usertypeuid"
            class="custom-field"
            :class="{ errorCustomField: v$.usertypeuid.$error }"
          >
            <option
              v-for="(item, index) in userType"
              :key="index"
              v-bind:value="item.type_uid"
            >
              {{ item.type_user }}
            </option>
          </select>
        </div>
        <div class="inputCol">
          <label
            >Username
            <span class="text-danger error-text">
              *
              <span v-if="v$.user_username.$error"> username is required </span>
            </span></label
          >
          <input
            v-model="formFields.user_username"
            type="text"
            class="custom-field"
            :class="{ errorCustomField: v$.user_username.$error }"
          />
        </div>
        <!-- <div class="inputCol">
          <label
            >Password
            <span class="text-danger error-text">
              *
              <span v-if="v$.user_password.$error"> password is required </span>
            </span></label
          >
          <input
            v-model="formFields.user_password"
            type="password"
            class="custom-field"
            :class="{ errorCustomField: v$.user_password.$error }"
          />
        </div> -->
        <div class="inputCol text-end">
          <a href="/">Login</a>
        </div>
        <div class="button-outer inputCol">
          <button class="custom-button">Email Reset Link</button>
        </div>
        <!-- <div class="inputCol text-center">
          <a class="custom-link" @click="authFB">
                    Login with Facebook
                </a>
         </div> -->
      </form>
    </div>
  </div>
</template>
<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import callService from "@/services/Service";
const { getData, getByID, postData } = callService();
export default {
  name: "ForgotPassword",
  data() {
    return {
      userGroup: [],
      userType: [],
      errorMessage: null,
      successMessage: null,
    };
  },
  setup() {
    const formFields = reactive({
      usergroupuid: "",
      usertypeuid: "",
      user_username: "",
    });

    const rules = {
      usergroupuid: {
        required,
      },
      usertypeuid: {
        required,
      },
      user_username: {
        required,
      },
    };
    const v$ = useVuelidate(rules, formFields);
    return {
      formFields,
      v$,
    };
  },
  async created() {
    if (localStorage.getItem("LogIn")) {
      const type_uid = JSON.parse(localStorage.getItem("LogIn")).type_uid;
      const redirction =
        type_uid === "ipg4bi4vg5"
          ? "SuperUserDashboard"
          : type_uid === "ipg4bi4vg6"
          ? "EnrollmentAdminProfile"
          : type_uid === "ipg4bi4vg7"
          ? "ClinicalAdminProfile"
          : type_uid === "ipg4bi4vg8"
          ? "GeneralAdminProfile"
          : type_uid === "ipg4bi4v10"
          ? "agentprofile"
          : type_uid === "ipg4bi4vg9"
          ? "EnrollmentManagerProfile"
          : type_uid === "ipg4bi4v11"
          ? "PhysicianAdminProfile"
          : type_uid === "ipg4bi4v12"
          ? "PhysicianProfile"
          : type_uid === "Enrollment"
          ? "managerProfile"
          : "/login";
      this.$router.push({ name: redirction });
    }

    try {
      const resData = await getData("login/getUserGroup");
      if (resData.status === 200 && resData.data.statuscode === 1) {
        this.userGroup = resData.data.result;
      }
    } catch (e) {
      this.error = e;
    }
  },
  methods: {
    async selectGroup(uid) {
      console.log("uid", uid);
      try {
        const resData = await getByID("login/getUserById/", uid);
        if (resData.status === 200 && resData.data.statuscode === 1) {
          this.userType = resData.data.result;
        }
      } catch (e) {
        this.error = e;
      }
    },
    hideError: function () {
      this.errorMessage = null;
      this.successMessage = null;
    },
    authFB() {
      this.$router.push({ name: "Enrollment" });
    },
    async submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          const resData = await postData("login/forgotPassword", this.formFields);
          if (resData.status === 200 && resData.data.statuscode === 1) {
            this.successMessage = 'Please check your email for reset link.';
            setTimeout(
              function () {
                this.hideError();
              }.bind(this),
              4000
            );
          } else {
            this.$router.push("/forgotpassword");
            this.errorMessage = resData.data.message;
            setTimeout(
              function () {
                this.hideError();
              }.bind(this),
              4000
            );
          }
        } catch (e) {
          this.error = e;
        }
      }
    },
  },
};
</script>
<style scoped>
.hClass {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #999393;
  line-height: 0.1em;
  margin: 18px 0 22px;
}

.hClass span {
  background: #fff;
  padding: 0 10px;
}
</style>