<template>
  <nav>
    <div class="header-logo">
      <img src="../../assets/img/logo.png" />
    </div>
    <div class="header-logo headerClone">
      <img src="../../assets/img/short-logo.png" />
    </div>
    <ul class="menu-outer" v-if="this.loginUser.type_uid == 'ipg4bi4vg5'">
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('SuperUserDashboard')"
          :class="{ active: nameUrl == 'SuperUserDashboard' }"
          ><span class="bi-speedometer2 menu-icon"></span>
          <span class="menu-name">Dashboard</span></a
        >
      </li>
      <li>
        <a href="javascript:void(0);"
          class="menu-link menu-link-dropdown"
          data-bs-toggle="collapse"
          data-bs-target="#UserAgentDrop"
          aria-expanded="false"
          aria-controls="UserAgentDrop"
          ><span class="bi-people-fill menu-icon"></span>
          <span class="menu-name"
            >Administrator
            <span class="dropicon"
              ><img
                src="../../assets/img/chevron-down.svg"
                alt="" /></span></span></a>
      </li>
      <ul class="innerDropdownMenu collapse multi-collapse" id="UserAgentDrop">
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('SuperUserList')"
            :class="{ active: nameUrl == 'SuperUserList' }"
            ><span class="bi-person-bounding-box menu-icon"></span>
            <span class="menu-name">Super User</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('EnrollmentAdminList')"
            :class="{ active: nameUrl == 'EnrollmentAdminList' }"
            ><span class="bi-person-workspace menu-icon"></span>
            <span class="menu-name">Enrollment Admin</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('ClinicalAdminList')"
            :class="{ active: nameUrl == 'ClinicalAdminList' }"
            ><span class="bi-person-video3 menu-icon"></span>
            <span class="menu-name">Clinical Admin</span></a
          >
        </li>
      </ul>
      <li>
        <a
          href="javascript:void(0);"
          class="menu-link menu-link-dropdown"
          data-bs-toggle="collapse"
          data-bs-target="#super_enrollment"
          aria-expanded="false"
          aria-controls="super_enrollment"
          ><span class="bi-person-vcard-fill menu-icon"></span>
          <span class="menu-name"
            >Enrollment
            <span class="dropicon"
              ><img
                src="../../assets/img/chevron-down.svg"
                alt="" /></span></span
        ></a>
      </li>
      <ul
        class="innerDropdownMenu collapse multi-collapse"
        id="super_enrollment"
      >
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('EnrollmentManagerList')"
            :class="{ active: nameUrl == 'EnrollmentManagerList' }"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">Manager</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('EnrollmentAgentList')"
            :class="{ active: nameUrl == 'EnrollmentAgentList' }"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">Agent</span></a
          >
        </li>
      </ul>
      <li>
        <a
          href="javascript:void(0);"
          class="menu-link menu-link-dropdown"
          data-bs-toggle="collapse"
          data-bs-target="#super_physician"
          aria-expanded="false"
          aria-controls="super_physician"
          ><span class="bi-person-rolodex menu-icon"></span>
          <span class="menu-name"
            >Physician
            <span class="dropicon"
              ><img
                src="../../assets/img/chevron-down.svg"
                alt="" /></span></span
        ></a>
      </li>
      <ul
        class="innerDropdownMenu collapse multi-collapse"
        id="super_physician"
      >
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('PhysicianAdminList')"
            :class="{ active: nameUrl == 'PhysicianAdminList' }"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">Physician Admin</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('PhysicianNpList')"
            :class="{ active: nameUrl == 'PhysicianNpList' }"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">Physician/NP</span></a
          >
        </li>
      </ul>

      <li>
        <a
          href="javascript:void(0);"
          class="menu-link menu-link-dropdown"
          data-bs-toggle="collapse"
          data-bs-target="#super_patient"
          aria-expanded="false"
          aria-controls="super_patient"
          ><span class="bi-person-rolodex menu-icon"></span>
          <span class="menu-name"
            >Patient
            <span class="dropicon"
              ><img
                src="../../assets/img/chevron-down.svg"
                alt="" /></span></span
        ></a>
      </li>
      <ul
        class="innerDropdownMenu collapse multi-collapse"
        id="super_patient"
      >
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('AllPatient')"
            :class="{ active: nameUrl == 'AllPatient' }"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">All Patient</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('SuperuserRefill')"
            :class="{ active: nameUrl == 'SuperuserRefill' }"
            ><span class="bi bi-bootstrap-reboot menu-icon"></span>
            <span class="menu-name">Refill To Patients</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('SuperuserRefund')"
            :class="{ active: nameUrl == 'SuperuserRefund' }"
            ><span class="bi bi-bootstrap-reboot menu-icon"></span>
            <span class="menu-name">Refund Patients</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('WaitingToAssign')"
            :class="{ active: nameUrl == 'WaitingToAssign' }"
            ><span class="bi bi-bootstrap-reboot menu-icon"></span>
            <span class="menu-name">Waiting To Assign</span></a
          >
        </li>
        <!-- <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('PhysicianNpList')"
            :class="{ active: nameUrl == 'PhysicianNpList' }"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">Physician/NP</span></a
          >
        </li> -->
      </ul>


      <li>
        <a
          href="javascript:void(0);"
          class="menu-link menu-link-dropdown"
          data-bs-toggle="collapse"
          data-bs-target="#super_dailer"
          aria-expanded="false"
          aria-controls="super_dailer"
          ><span class="bi-telephone menu-icon"></span>
          <span class="menu-name"
            >Dailer
            <span class="dropicon"
              ><img
                src="../../assets/img/chevron-down.svg"
                alt="" /></span></span
        ></a>
      </li>
      <ul class="innerDropdownMenu collapse multi-collapse" id="super_dailer">        
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('AssignExtension')"
            :class="{ active: nameUrl == 'AssignExtension' }"
            ><span class="bi bi-building menu-icon"></span>
            <span class="menu-name">Assign Extension</span></a
          >
        </li>
      </ul> 
    </ul>
    <ul class="menu-outer" v-else-if="this.loginUser.type_uid == 'ipg4bi4vg6'">
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('EnrollmentAdminDashboard')"
          :class="{ active: nameUrl == 'EnrollmentAdminDashboard' }"
          ><span class="bi-speedometer2 menu-icon"></span>
          <span class="menu-name">Dashboard</span></a
        >
      </li>
      <li>
        <a
          href="javascript:void(0);"
          class="menu-link menu-link-dropdown"
          data-bs-toggle="collapse"
          data-bs-target="#UserAgentDrop"
          aria-expanded="false"
          aria-controls="UserAgentDrop"
          ><span class="bi-people-fill menu-icon"></span>
          <span class="menu-name"
            >Users
            <span class="dropicon"
              ><img
                src="../../assets/img/chevron-down.svg"
                alt="" /></span></span
        ></a>
      </li>
      <ul class="innerDropdownMenu collapse multi-collapse" id="UserAgentDrop">
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('ManagerList')"
            :class="{ active: nameUrl == 'ManagerList' }"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">Manager</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('AgentList')"
            :class="{ active: nameUrl == 'AgentList' }"
            ><span class="bi-file-earmark-person menu-icon"></span>
            <span class="menu-name">Agent</span></a
          >
        </li>
      </ul>
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('EnrollmentAdminFaqList')"
          :class="{ active: nameUrl == 'EnrollmentAdminFaqList' }"
          ><span class="bi bi-patch-question menu-icon menu-icon"></span>
          <span class="menu-name">FAQs</span></a
        >
      </li>
    </ul>
    <ul class="menu-outer" v-else-if="this.loginUser.type_uid == 'ipg4bi4v10'">
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('enrollmentform')"
          :class="{ active: nameUrl == 'enrollmentform' }"
          ><span class="bi-speedometer2 menu-icon"></span>
          <span class="menu-name">Enrollment</span></a
        >
      </li>
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('enrollmentfaq')"
          :class="{ active: nameUrl == 'enrollmentfaq' }"
          ><span class="bi bi-patch-question menu-icon"></span>
          <span class="menu-name">FAQ</span></a
        >
      </li>
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('enrollmentlist')"
          :class="{ active: nameUrl == 'enrollmentlist' }"
          ><span class="bi-search menu-icon"></span>
          <span class="menu-name">Search Patients</span></a
        >
      </li>
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('enrollmentonline')"
          :class="{ active: nameUrl == 'enrollmentonline' }"
          ><span class="bi bi-person-check menu-icon"></span>
          <span class="menu-name">Online Agent</span></a
        >
      </li>

      
    </ul>
    <ul class="menu-outer" v-else-if="this.loginUser.type_uid == 'ipg4bi4vg9'">
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('EnrollmentManagerDashboard')"
          :class="{ active: nameUrl == 'EnrollmentManagerDashboard' }"
          ><span class="bi-speedometer2 menu-icon"></span>
          <span class="menu-name">Dashboard</span></a
        >
      </li>
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('EnrollmentManagerAgentList')"
          :class="{
            active:
              nameUrl == 'EnrollmentManagerAgentList' ||
              nameUrl == 'EnrollmentManagerEditAgent' ||
              nameUrl == 'EnrollmentManagerCreateAgent',
          }"
          ><span class="bi-speedometer2 menu-icon"></span>
          <span class="menu-name">Enrollment Agent</span></a
        >
      </li>
      <li>
        <a
          href="javascript:void(0);"
          class="menu-link menu-link-dropdown"
          data-bs-toggle="collapse"
          data-bs-target="#manager_patient"
          aria-expanded="false"
          aria-controls="manager_patient"
          ><span class="bi-people-fill menu-icon"></span>
          <span class="menu-name"
            >Patients
            <span class="dropicon"
              ><img src="../../assets/img/chevron-down.svg" alt="" /></span></span
        ></a>
      </li>
      <ul class="innerDropdownMenu collapse multi-collapse" id="manager_patient">
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('AllPatientList')"
            :class="{ active: nameUrl == 'AllPatientList' }"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">All Patients</span></a>
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('BillingVerificationPatient')"
            :class="{ active: nameUrl == 'BillingVerificationPatient'}"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">Billing Verification</span></a>
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('RXBillingConfirmation')"
            :class="{ active: nameUrl == 'RXBillingConfirmation' }"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">RX Billing Confirmation</span></a>
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('PatientNotes')"
            :class="{ active: nameUrl == 'PatientNotes' }"
            ><span class="bi-file-earmark-person-fill menu-icon"></span>
            <span class="menu-name">Note</span></a>
        </li>
            <!-- <li><a href="#" class="menu-link" @click="menuClick('PatientList')" :class="{ active: nameUrl == 'PatientList' }"><span class="bi-file-earmark-person menu-icon"></span>
                  <span class="menu-name">Completed Patients</span></a></li> -->
      </ul>
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('FaqList')"
          :class="{
            active:
              nameUrl == 'FaqList' ||
              nameUrl == 'FaqCreate' ||
              nameUrl == 'FaqEdit',
          }"
          ><span class="bi-speedometer2 menu-icon"></span>
          <span class="menu-name">FAQ</span></a
        >
      </li>
      <li>
        <a
          href="javascript:void(0);"
          class="menu-link menu-link-dropdown"
          data-bs-toggle="collapse"
          data-bs-target="#super_dailer"
          aria-expanded="false"
          aria-controls="super_dailer"
          ><span class="bi-telephone menu-icon"></span>
          <span class="menu-name"
            >Dailer
            <span class="dropicon"
              ><img
                src="../../assets/img/chevron-down.svg"
                alt="" /></span></span
        ></a>
      </li>
      <ul class="innerDropdownMenu collapse multi-collapse" id="super_dailer">        
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('EnrollmentManagerAssignExtension')"
            :class="{ active: nameUrl == 'EnrollmentManagerAssignExtension' }"
            ><span class="bi bi-building menu-icon"></span>
            <span class="menu-name">Assign Extension</span></a
          >
        </li>
      </ul> 
    </ul>
    <ul class="menu-outer" v-else-if="this.loginUser.type_uid == 'ipg4bi4v11'">
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('PhysicianAdminDashboard')"
          :class="{ active: nameUrl == 'PhysicianAdminDashboard' }"
          ><span class="bi-speedometer2 menu-icon"></span>
          <span class="menu-name">Dashboard</span></a
        >
      </li>
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('PhysicianAdminListPhysician')"
          :class="{
            active:
              nameUrl == 'PhysicianAdminListPhysician' ||
              nameUrl == 'PhysicianAdminCreatePhysician' ||
              nameUrl == 'PhysicianAdminEditPhysician',
          }"
          ><span class="bi-person-badge-fill menu-icon"></span>
          <span class="menu-name">Physicians</span></a
        >
      </li>
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('PhysicianAdminAssignPhysician')"
          :class="{ active: nameUrl == 'PhysicianAdminAssignPhysician' }"
          ><span class="bi-repeat menu-icon"></span>
          <span class="menu-name">Doctor Assign</span></a
        >
      </li>      
      <li>
        <a href="#"
          class="menu-link"
          @click="menuClick('PhysicianAdminAllPatients')"
          :class="{ active: nameUrl == 'PhysicianAdminAllPatients' }"
          ><span class="bi bi-people-fill menu-icon"></span>
          <span class="menu-name">All Patients</span></a>
      </li>      
      <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('PhysicianAdminRefund')"
            :class="{ active: nameUrl == 'PhysicianAdminRefund' }"
            ><span class="bi bi-currency-dollar menu-icon"></span>
            <span class="menu-name">Refund Patients</span></a
          >
        </li>
      <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('WaitingToAssignByPhysician')"
            :class="{ active: nameUrl == 'WaitingToAssignByPhysician' }"
            ><span class="bi bi-bootstrap-reboot menu-icon"></span>
            <span class="menu-name">Waiting To Assign</span></a
          >
        </li>
      <li>
        <a href="javascript:void(0);"
          class="menu-link menu-link-dropdown"
          data-bs-toggle="collapse"
          data-bs-target="#completed_Consults"
          aria-expanded="false"
          aria-controls="completed_Consults"
          ><span class="bi-people-fill menu-icon"></span>
          <span class="menu-name"
            >Completed Consults
            <span class="dropicon"
              ><img
                src="../../assets/img/chevron-down.svg"
                alt="" /></span></span
        ></a>
      </li>
      <ul class="innerDropdownMenu collapse multi-collapse" id="completed_Consults">
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('PhysicianAdminCompleteRx')"
            :class="{ active: nameUrl == 'PhysicianAdminCompleteRx' }"
            ><span class="bi bi-prescription menu-icon"></span>
            <span class="menu-name">Completed Rx</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('PhysicianAdminDeniedRx')"
            :class="{ active: nameUrl == 'PhysicianAdminDeniedRx' }"
            ><span class="bi-file-earmark-person menu-icon"></span>
            <span class="menu-name">Denied Rx</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('PhysicianAdminReturnToWaitingRoom')"
            :class="{ active: nameUrl == 'PhysicianAdminReturnToWaitingRoom' }"
            ><span class="bi-file-earmark-person menu-icon"></span>
            <span class="menu-name">Returned to Waiting room</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('PhysicianAdminReturnToPatientAssign')"
            :class="{ active: nameUrl == 'PhysicianAdminReturnToPatientAssign' }"
            ><span class="bi-file-earmark-person menu-icon"></span>
            <span class="menu-name">Returned to Assign</span></a
          >
        </li>
        <li>
          <a
            href="#"
            class="menu-link"
            @click="menuClick('PhysicianAdminRefill')"
            :class="{ active: nameUrl == 'PhysicianAdminRefill' }"
            ><span class="bi bi-bootstrap-reboot menu-icon"></span>
            <span class="menu-name">Refill To Patients</span></a
          >
        </li>
        
      </ul>
    </ul>
    <ul class="menu-outer" v-else-if="this.loginUser.type_uid == 'ipg4bi4v12'">
      <li>
        <a
          href="#"
          class="menu-link"
          @click="menuClick('WaitingRoom')"
          :class="{ active: nameUrl == 'WaitingRoom' }"
          ><span class="bi-speedometer2 menu-icon"></span>
          <span class="menu-name">Waiting Room</span></a
        >
      </li>
      <li><a
          href="#"
          class="menu-link"
          @click="menuClick('PhysicianCompleteRx')"
          :class="{ active: nameUrl == 'PhysicianCompleteRx' }">
          <span class="bi-file-earmark-person menu-icon"></span>
          <span class="menu-name">Completed Rx</span></a>
        </li>
    </ul>
  </nav>
</template>
<script>
import { useRoute } from "vue-router";
export default {
  name: "Navbar",
  data() {
    return {
      nameUrl: null,
      loginUser: JSON.parse(localStorage.getItem("LogIn")),
    };
  },
  mounted() {
    //this.getCookie();
    this.nameUrl = this.$route.name;
  },
  methods: {
    menuClick(url) {
      this.$router.push({ name: url });
      this.nameUrl = url;
    },
  },
};
</script>

