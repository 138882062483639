<template>
  <div class="loginOuter">
    <div class="loginForm custom-form">
      <div class="logo-heaer">
        <img src="../assets/img/logo.png" />
      </div>
      <form class="loginField" @submit.prevent="submitForm">
        <div
          class="alert alert-danger"
          :class="{ 'alert-active': errorMessage != null }"
        >
          <strong>Error!</strong> {{ errorMessage }}
        </div>
        <div
          class="alert alert-success"
          :class="{ 'alert-active': successMessage != null }"
        >
          <strong>Success!</strong> {{ successMessage }}
        </div>
        <div class="inputCol">
          <label
            >New Password
            <span class="text-danger error-text">
              *
              <span v-if="v$.user_password.$error"> username is required </span>
            </span></label
          >
          <input
            v-model="formFields.user_password"
            type="password"
            class="custom-field"
            :class="{ errorCustomField: v$.user_password.$error }"
          />
        </div>
        <div class="inputCol">
          <label
            >Confirm Password
            <span class="text-danger error-text">
              *
              <span v-if="v$.user_conf_password.$error">
                username is required
              </span>
            </span></label
          >
          <input
            v-model="formFields.user_conf_password"
            type="text"
            class="custom-field"
            @blur="checkPassword"
            :class="{ errorCustomField: v$.user_conf_password.$error }"
          />
        </div>
        <div class="inputCol text-end">
          <a href="/">Login</a>
        </div>
        <div class="button-outer inputCol">
          <button
            class="custom-button"
            v-bind:disabled="alert_message != null ? true : false"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import callService from "@/services/Service";
const { getData, getByID, postData } = callService();

export default {
  name: "ResetPassword",
  data() {
    return {
      userGroup: [],
      userType: [],
      errorMessage: null,
      successMessage: null,
      alert_message: "Not null",
      token: this.$route.params.token,
    };
  },
  setup() {
    const formFields = reactive({
      user_password: "",
      user_conf_password: "",
    });

    const rules = {
      user_password: {
        required,
      },
      user_conf_password: {
        required,
      },
    };
    const v$ = useVuelidate(rules, formFields);
    return {
      formFields,
      v$,
    };
  },
  mount() {
    this.checkToken();
  },
  async created() {
    if (localStorage.getItem("LogIn")) {
      const type_uid = JSON.parse(localStorage.getItem("LogIn")).type_uid;
      const redirction =
        type_uid === "ipg4bi4vg5"
          ? "SuperUserDashboard"
          : type_uid === "ipg4bi4vg6"
          ? "EnrollmentAdminProfile"
          : type_uid === "ipg4bi4vg7"
          ? "ClinicalAdminProfile"
          : type_uid === "ipg4bi4vg8"
          ? "GeneralAdminProfile"
          : type_uid === "ipg4bi4v10"
          ? "agentprofile"
          : type_uid === "ipg4bi4vg9"
          ? "EnrollmentManagerProfile"
          : type_uid === "ipg4bi4v11"
          ? "PhysicianAdminProfile"
          : type_uid === "ipg4bi4v12"
          ? "PhysicianProfile"
          : type_uid === "Enrollment"
          ? "managerProfile"
          : "/login";
      this.$router.push({ name: redirction });
    }

    try {
      const resData = await getData("login/getUserGroup");
      if (resData.status === 200 && resData.data.statuscode === 1) {
        this.userGroup = resData.data.result;
      }
    } catch (e) {
      this.error = e;
    }

    this.checkToken();
  },
  methods: {
    checkPassword() {
      this.formFields.user_password = this.formFields.user_password.trim();
      if (
        this.formFields.user_password.trim() ==
        this.formFields.user_conf_password.trim()
      ) {
        this.successMessage = "Confirm Password Match";
        this.alert_message = null;
        setTimeout(
          function () {
            this.hideError();
          }.bind(this),
          4000
        );
      } else {
        this.errorMessage = "Confirm Password Not Match";
        this.alert_message = "Not Matach";
        setTimeout(
          function () {
            this.hideError();
          }.bind(this),
          4000
        );
      }
    },
    async checkToken() {
      try {
        const resData = await getByID("login/checkToken/", this.token);
        if (resData.status === 200 && resData.data.statuscode === 1) {
          //this.successMessage = resData.data.message;
        } else {
          this.errorMessage = resData.data.message;
        }
      } catch (e) {
        this.error = e;
      }
    },
    async selectGroup(uid) {
      console.log("uid", uid);
      try {
        const resData = await getByID("login/getUserById/", uid);
        if (resData.status === 200 && resData.data.statuscode === 1) {
          this.userType = resData.data.result;
        }
      } catch (e) {
        this.error = e;
      }
    },
    hideError: function () {
      this.errorMessage = null;
      this.successMessage = null;
    },
    async submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (
          this.formFields.user_password.trim() ==
          this.formFields.user_conf_password.trim()
        ) {
          try {
            const resData = await postData("login/resetPassword", {
              new_password: this.formFields.user_password,
              user_token: this.token,
            });
            if (resData.status === 200 && resData.data.statuscode === 1) {
              this.successMessage = "Password Updated Successfully.";
              setTimeout(
                function () {
                  this.hideError();
                  this.$router.push("/login/");
                }.bind(this),
                4000
              );
            } else {
              this.$router.push("/resetpassword/" + this.token);
              this.errorMessage = resData.data.message;
              setTimeout(
                function () {
                  this.hideError();
                }.bind(this),
                4000
              );
            }
          } catch (e) {
            this.error = e;
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.hClass {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #999393;
  line-height: 0.1em;
  margin: 18px 0 22px;
}

.hClass span {
  background: #fff;
  padding: 0 10px;
}
</style>